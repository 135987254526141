import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const getCosBucketRegion = () => {
  return ajax.get(`${base}/api/itedu/v1/cos/bucket-region`);
};

const getCosAppId = () => {
  return ajax.get(`${base}/api/itedu/v1/cos/appid`);
};

const getCosStsUrl = () => {
  return `/api/itedu/v1/cos/appid`;
};

//有
const getTmpCosObject = (bizType, fileName) => {
  ///api/itedu/v1/cos/tmp-cos-object
  return ajax.get(`${base}/api/itedu/v1/cos/tmp-cos-object?bizType=${bizType}&fileName=${fileName}`);
};

//有
const getCdnUrl = (key) => {
  return ajax.get(`${base}/api/itedu/v1/cos/cdn-url?key=${key}`);
};

//有
const getDogeCloudTmpToken = (bizType, fileName) => {
  return ajax.get(`${base}/api/itedu/v1/doge-cloud/tmp-token?bizType=${bizType}&fileName=${fileName}`);
};
//有
const getDogeCloudVodUploadDone = (did) => {
  return ajax.get(`${base}/api/itedu/v1/doge-cloud/vod-upload-done?did=${did}`);
};
//有
const setCosTmpFinish = (id) => {
  return ajax.post(`${base}/api/itedu/v1/cos/tmp-cos-set-finish?id=${id}`);
};

//有
const setDogeCosTmpFinish = (id, vid, vcode, did) => {
  return ajax.post(`${base}/api/itedu/v1/cos/doge-tmp-cos-set-finish?id=${id}&vid=${vid}&vcode=${vcode}&did=${did}`);
};

//有
const batchAddCos = (cosObjectList) => {
  return ajax.post(`${base}/api/itedu/v1/cos/batch-add`, cosObjectList);
};

const deleteCosByYou = (cosId) => {
  ///api/itedu/v1/cos/delete-by-you
  return ajax.post(`${base}/api/itedu/v1/cos/delete-by-you?cosId=${cosId}`);
};

const awaitChain = (promise) => {
  return promise.then((data) => [data, null]).catch((err) => [null, err]);
};

export const cosApi = {
  // import时得花括号明确
  getCosBucketRegion: getCosBucketRegion,
  getCosAppId: getCosAppId,
  getCosStsUrl: getCosStsUrl,
  getTmpCosObject: getTmpCosObject,
  getCdnUrl: getCdnUrl,
  getDogeCloudTmpToken: getDogeCloudTmpToken,
  getDogeCloudVodUploadDone: getDogeCloudVodUploadDone,
  setCosTmpFinish: setCosTmpFinish,
  setDogeCosTmpFinish: setDogeCosTmpFinish,
  batchAddCos: batchAddCos,
  deleteCosByYou: deleteCosByYou,
  awaitChain: awaitChain,
};
